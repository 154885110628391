/************ Custom Font ************/
:root {
  --theme-primary: #1e90ff;
  --theme-secondary: #ffffff;
}

/************ Default Css ************/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  /* font-weight: 400; */
  /* overflow-x: hidden; */
  /* position: relative; */
  /* background-color: #121212 !important; */
  color: #fff !important;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

p, a, li, button, ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a {
  text-decoration: none !important;
}

img {
  width: 100%;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus, textarea:focus, select:focus {
  outline: none;
}

@media (min-width:1700px) {
	main .container {
		max-width: 100%;
		padding: 0 150px;
	}
}

@media (max-width: 767px) {
  .hidden-passo-mobile {
    display: none !important;
  }

  .show-passo-mobile {
    display: block !important;    
  }

  .adjust-buttom-font-size {
    font-size: 16px !important;
  }

  .adjust-img-homem-mobile {
    width: 280px !important;
    justify-content: center !important;
    align-items: center !important;
    display: flex !important;
    align-items: center !important;
    margin: 0px !important;
    padding: 0px !important;    
    
  }

}

p.success {
	color: green;
}

p.danger {
	color: red;
}
/************ Navbar Css ************/
nav.navbar {
  padding: 18px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition:  0.32s ease-in-out;
}
nav.navbar.scrolled {
  padding: 0px 0;
  background-color: #172f4d;
}

@media (max-width: 768px) {
  /* CSS that should be displayed if width is equal to or less than 800px goes here */
  nav.navbar a.navbar-brand {
    width: 33% !important;
  }
}

nav.navbar a.navbar-brand {
    /* width: 9%; */
    width: 12%;
}
nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 400;
  color: #fff !important;
  letter-spacing: 0.8px;
  padding: 0 25px;
  font-size: 18px;
  opacity: 0.75;
}
nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
    opacity: 1;
}
span.navbar-text {
    display: flex;
    align-items: center;
}
.social-icon {
    display: inline-block;
    margin-left: 14px;
}
.social-icon a {
    width: 42px;
    height: 42px;
    background: rgba(217, 217, 217, 0.1);
    display: inline-flex;
    border-radius: 50%;
    margin-right: 6px;
    align-items: center;
    justify-content: center;
    line-height: 1;
    border: 1px solid rgba(255, 255, 255, 0.5);
}
.social-icon a::before {
    content: "";
    width: 42px;
    height: 42px;
    position: absolute;
    background-color: #ffffff;
    border-radius: 50%;
    transform: scale(0);
	transition: 0.3s ease-in-out;
}
.social-icon a:hover::before {
    transform: scale(1);
}
.social-icon a img {
	width: 40%;
	z-index: 1;
	transition: 0.3s ease-in-out;
}
.social-icon a:hover img {
	filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
}
.navbar-text button {
  font-weight: 700;
  color: #fff;
  border: 1px solid #fff;
  padding: 18px 34px;
  font-size: 18px;
  margin-left: 18px;
	position: relative;
	background-color: transparent;
	transition: 0.3s ease-in-out;
}
.navbar-text button span {
  z-index: 1;
}
.navbar-text button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #fff;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}
.navbar-text button:hover {
  color: #121212;
}
.navbar-text button:hover::before {
	content: "";
	width: 100%;
	height: 100%;
	position: absolute;
}
nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
	outline: none;
    box-shadow: none;
}
nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid #fff;
  transition: all 300ms linear;
  top: -2px;
}
nav.navbar .navbar-toggler-icon:focus {
	border-bottom: 2px solid #fff;
}
nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
	width: 24px;
	position: absolute;
	height: 2px;
	background-color: #fff;
	top: 0;
	left: 0;
	content: '';
	z-index: 2;
	transition: all 300ms linear;
}
nav.navbar .navbar-toggler-icon:after {
	top: 8px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
	transform: rotate(45deg);
	background-color: #fff;
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
	transform: translateY(8px) rotate(-45deg);
	background-color: #fff;
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
	border-color: transparent;
}

.eficienciaEnergetica {
  width: 100%;
  min-height: 582;
  background-color: #EEF1F4;
  color: #1c4670 !important;
  padding-top: 50px;
  padding-bottom: 72px;
}

.eficienciaEnergetica button {
  color: #172f4d;
  font-weight: 700;
  font-size: 20px;
  letter-spacing: 0.8px;
}
.eficienciaEnergetica button svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}
.eficienciaEnergetica button:hover svg {
  margin-left: 25px;
}


.contasDeEnergias {
  width: 100%;
  min-height: 582;
  background-color: #F2F7FD;
  color: #1c4670 !important;
  padding-top: 50px;
  padding-bottom: 72px;
}

.rowContasIcons {
  background-color: #172f4d;
  border-radius: 8px;
  justify-content: center; 
  padding: 16px 32px 0px 32px;
}

.rowContasIcons p {
  color: #FFFFFF;
  padding-top: 4px;
  margin: 0px;
}

.rowContasIcons div {
  padding-bottom: 16px;
}

.rowContasIcons img {
  margin-right: 15px;
}

/************ Banner Css ************/
.banner {
  margin-top: 0;
  padding: 160px 0 0px 0;
  background-image: url('./assets/img/bg-principal.png');
  width: 100%;
  /*min-height: 739px;*/
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}
.banner .tagline {
  width: 236px;
  height: 63px;
}
.banner h1 {
  font-size: 42px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
}

.banner p {
  color: #FFFFFF;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
}
.banner button {
  color: #172f4d;
  font-weight: 700;
  font-size: 20px;
  margin-top: 60px;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
}
.banner button svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}
.banner button:hover svg {
  margin-left: 25px;
}
.banner .logoHeader {
  /* animation: updown 3s linear infinite; */
  animation: spin 10s linear infinite;
}
.display-center {
  align-items: center;
  justify-content: center;
}
@keyframes updown {
    0% {
        transform: translateY(-20px);
    }
    50% {
        transform: translateY(20px);
    }
    100% {
        transform: translateY(-20px);
    }
}

@keyframes spin{
	100%{
		-webkit-transform:rotate(360deg);
		transform:rotate(360deg);
	}
}
.txt-rotate > .wrap {
  border-right: 0.08em solid #666;
  line-height: 120%;
  color: #ff9d0a;
}

/************ Skills Css ************/
.skill {
  background-image: url('./assets/img/bg-section-03.png');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  
}
.skill-bx {
  background: #151515;
  border-radius: 20px;
  text-align: center;
  padding: 60px 50px;
  margin-top: -60px;
}
.skill h1 {
  color: #f2f7fd;
  
}

.skill h2 {
	font-size: 45px;
	font-weight: 700;
}

.skill p {
    color: #f2f7fd;
    font-size: 18px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
    text-align: left;
}
.skill-slider {
  width: 100%;
  margin: 0 auto;
  position: relative;
}
.skill-slider .item img {
  width: 50%;
  margin: 0 auto 15px auto;
}
.background-image-left {
  top: 28%;
  position: absolute;
  bottom: 0;
  width: 40%;
  z-index: -4;
}
/************ Beneficios Css ************/
.benefits {
  background-color: #172F4D !important;
  padding-bottom: 72px;
}

@media (max-width: 768px) {
  .benefits p:first-child {
    font-size: 24px !important;
  }
}

.benefits p:first-child {
  color: #F2F7FD; 
  text-align: center;
  font-size: 40px;
  line-height: 120%;
  margin-bottom: 16px;
  font-weight: 800;
}

.benefits .benefits-logo-raio {
  width: 24px !important;
  height: 28px !important;
  margin-bottom: 16px !important;
}

.benefits .cards {
  min-width: 316px;
  min-height: 231px;
  border-top-left-radius: 8px;
  border-top-right-radius: 64px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

/************ Projects Css ************/
.project {
  padding: 80px 0;
  position: relative;
  background-color: black;
}
.project h2 {
	font-size: 45px;
	font-weight: 700;
  text-align: center;
}
.project p {
  color: #B8B8B8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px auto 30px auto;
  text-align: center;
  width: 56%;
}
.project .nav.nav-pills {
  width: 72%;
  margin: 0 auto;
  border-radius: 8px;
  background-color: rgb(255 255 255 / 10%);
  overflow: hidden;
}
.project .nav.nav-pills .nav-item {
  width: 33.33333%;
}
.project .nav.nav-pills .nav-link {
  background-color: transparent;
  border-radius: 0;
  padding: 17px 0;
  color: #fff;
  width: 100%;
  font-size: 17px;
  letter-spacing: 0.8px;
  font-weight: 500;
  position: relative;
  transition: 0.3s ease-in-out;
  text-align: center;
  z-index: 0;
}
.project .nav.nav-pills .nav-link::before {
    content: "";
    position: absolute;
    width: 0;
    height: 100%;
    /* background: linear-gradient(90.21deg, rgba(33, 57, 194, 0.5) -5.91%, rgba(0, 8, 53, 0.5) 111.58%); */
    background: linear-gradient(90.21deg, #AA367C -5.91%, #000835 111.58%);
    /* background: linear-gradient(90.21deg, #2139c2 -5.91%, #000835 111.58%); */
    top: 0;
    left: 0;
    z-index: -1;
    transition: 0.3s ease-in-out;
}
.project .nav.nav-pills .nav-link.active::before {
    width: 100% !important;
}
.project .nav.nav-pills .nav-link.active {
    border: 1px solid rgba(255, 255, 255, 1);
}
.nav-link#projects-tabs-tab-first {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 8px 0px 0px 8px;
  cursor: pointer;
}
.nav-link#projects-tabs-tab-second {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  cursor: pointer;
}
.nav-link#projects-tabs-tab-third {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 0 8px 8px 0;
  cursor: pointer;
}

.proj-imgbx {
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 24px;
}
.proj-imgbx::before {
  content: "";
  background: linear-gradient(90.21deg, #AA367C -5.91%, #000835 111.58%);
  /* background: linear-gradient(90.21deg, #2139c2 -5.91%, #000835 111.58%); */

  opacity: 0.85;
  position: absolute;
  width: 100%;
  height: 0;
  transition: 0.4s ease-in-out;
}
.proj-imgbx:hover {
  cursor: pointer;
}
.proj-imgbx:hover::before {
  height: 100%;
}
.proj-txtx {
  position: absolute;
  text-align: center;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s ease-in-out;
  opacity: 0;
  width: 100%;
}
.proj-imgbx:hover .proj-txtx {
  top: 50%;
  opacity: 1;
}
.proj-txtx h4 {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.1em;
}
.proj-txtx span {
  font-style: italic;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.8px;
}
.background-image-right {
  top: 20%;
  position: absolute;
  bottom: 0;
  width: 35%;
  right: 0;
  z-index: -4;
}

/************ Projects Css ************/
.contact {
  /* background: linear-gradient(90.21deg, #AA367C -5.91%, #000835 111.58%); */
  background: linear-gradient(90.21deg, #AA367C -5.91%, #000835 111.58%);
  /* background: linear-gradient(90.21deg, #2139c2 -5.91%, #000835 111.58%); */
  
  padding: 60px 0 200px 0;
}
.contact img {
  width: 92%;
}
.contact h2 {
	font-size: 45px;
	font-weight: 700;
  margin-bottom: 30px;
}
.contact form input, .contact form textarea {
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 8px;
  color: #fff;
  margin: 0 0 10px 0;
  /* padding: 18px 26px; */
  padding: 12px 20px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}
.contact form input:focus, .contact form textarea:focus {
  background: rgba(255, 255, 255, 1);
  color: #121212;
}
.contact form input::placeholder, .contact form textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}
.contact form input:focus::placeholder, .contact form textarea:focus::placeholder {
  color: #121212;
  opacity: 0.8;
}
.contact form button {
  font-weight: 700;
  color: #000;
  background-color: #fff;
  padding: 14px 48px;
  font-size: 18px;
  margin-top: 25px;
  border-radius: 8px;
  position: relative;
  transition: 0.3s ease-in-out;
}
.contact form button span {
  z-index: 1;
  position: relative;
}
.contact form button:hover {
  color: #fff;
}
.contact form button::before {
  content: "";
  background: #121212;
  border-radius: 8px;
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transition: 0.3s ease-in-out;
}
.contact form button:hover::before {
  width: 100%;
}

/************ Footer Css ************/
.footer {
  padding: 0 0 50px 0;
  background-image: url('./assets/img/footer-bg.png');
  /* background-image: url('./assets/img/bg-footer.png'); */
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.newsletter-bx {
  background: #FFFFFF;
  /* border-radius: 55px; */
  border-radius: 8px;
  color: #121212;
  /* padding: 85px 125px; */
  padding: 85px 125px;
  margin-bottom: 80px;
  margin-top: -122px;
}
.newsletter-bx h3 {
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 1.2em;
}
.new-email-bx {
  background: #fff;
  padding: 5px;
  border-radius: 8px;
  position: relative;
  z-index: 0;
  display: flex;
  align-items: center;
}
.new-email-bx::before {
  content: "";
  background: linear-gradient(90.21deg, #AA367C -5.91%, #000835 111.58%);
  /* background: linear-gradient(90.21deg, #2139c2 -5.91%, #000835 111.58%); */
  border-radius: 8px;
  position: absolute;
  z-index: -1;
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
}
.new-email-bx::after {
  content: "";
  background: #fff;
  border-radius: 8px;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.new-email-bx input {
  width: 100%;
  color: #121212;
  font-weight: 500;
  background: transparent;
  border: 0;
  padding: 0 15px;
}
.new-email-bx button {
  background: linear-gradient(90.21deg, #AA367C -5.91%, #000835 111.58%);
  /* background: linear-gradient(90.21deg, #2139c2 -5.91%, #000835 111.58%); */
  padding: 20px 65px;
  color: #fff;
  font-weight: 500;
  letter-spacing: 0.5px;
  border-radius: 8px;
}

.banner-enviar {
  /* background: linear-gradient(90.21deg, #FFAA00 -5.91%, #FFD400 111.58%); */
  
  background: linear-gradient(to left, #FFD400 10%, #FFAA00 130%);
  padding: 12px 65px;
  font-weight: 700;
  letter-spacing: 0.5px;
  border-radius: 16px;
}

.footer img {
  width: 26%;
}
.footer p {
  font-weight: 400;
  font-size: 14px;
  color: #B8B8B8;
  letter-spacing: 0.5px;
  margin-top: 20px;
}

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
/* Modal */
@media (min-width: 768px) {
  .modal-dialog {
    min-width: 90% !important;
    max-width: none;
  }
  .modal-body {
    min-height: 80% !important;
    max-height: none;
  }
}

@media (min-width: 1367px) {
  .modal-dialog {
    /* min-width: 90% !important; */
    min-width: 65% !important;
    max-width: none;
  }
  .modal-body {
    min-height: 80% !important;
    max-height: none;
  }
}

.modal-header {
  background-color: #121212 !important;
  color: #B8B8B8 !important;
}

.modal-footer button {
  font-weight: 700;
  color: #000;
  background-color: #B8B8B8;
  padding: 14px 48px;
  font-size: 18px;
  margin-top: 10px;
  border-radius: 8px;
  position: relative;
  transition: 0.3s ease-in-out;
}
.modal-footer button span {
  z-index: 1;
  position: relative;
}
.modal-footer button:hover {
  color: #fff;
}
.modal-footer button::before {
  content: "";
  background: #121212;
  border-radius: 8px;
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transition: 0.3s ease-in-out;
}
.modal-footer button:hover::before {
  width: 100%;
}

/* Ajuste para celular */
@media (max-width: 390px) {
  .banner h1 {
    font-size: 38px;
  }
  .banner img {
    max-width: 72%;
    margin-left: 14%;
  }
  .skill h2 {
    font-size: 30px;
  }
  .project h2 {
    font-size: 30px;
  }
  .project .nav.nav-pills {
    width: 90%;
  }
  .project .nav.nav-pills .nav-link {
    font-size: 14px;
  }
  .newsletter-bx {
    padding: 55px 25px;
  }

  .new-email-bx input {
    padding: 0 10px;
  }
  .new-email-bx button {
    padding: 10px 10px;
  }
}


/************ Formulario Css ************/

.formulario {
  background: #D6F1FF;
  padding: 56px 0px;
}

.formulario-label {
  font-family: 'Red Hat Display';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #172F4D;
}

.formulario-label input {
  background: #EDFAFF;
  border: 1px solid #172F4D;
  border-radius: 8px;
  padding: 12px 24px;
}

.formulario-submit {
  padding: 16px 48px;
  background: linear-gradient(270deg, #FFD500 0%, #FF8A05 99.99%, #FFAA00 100%);
  border-radius: 16px;
  font-family: 'Red Hat Display';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #172F4D;
}

.formulario-text {
font-family: 'Red Hat Display';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 140%;
text-align: center;
color: #172F4D;

}


/************ Rodape Css ************/
.rodape {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #1C3C5E;
  padding: 60px 300px;
}

.rodape-start {
  display: flex;
  flex-direction: row;
}

.rodape-contact {
  display: grid;
  align-items: flex-start;
  gap: 10px;
}

.rodape-text {
  font-family: 'Red Hat Display' !important;
  font-style: normal !important;
  font-weight: 400;
  font-size: 20px;
  color: #FFFFFF;
}

.rodape-email {
  font-family: 'Red Hat Display' !important;
  font-style: normal !important;
  font-weight: 400;
  font-size: 16px;
  color: #FFFFFF;
}


@media (max-width: 576px) {
  .rodape {
    padding: 24px 0px;
  }

  .rodape-start {
    flex-direction: column;
    justify-content: center;
    padding: 24px 0px;
  }

  .rodape-start .rodape-centralize {
    justify-content: center !important;
  }

  .rodape-centralize {
    justify-content: center !important;
    text-align: center;
  }

  .rodape-start .rodape-contact {
    justify-content: center;
    padding-bottom: 24px;
  }

}

.botao-enviar {
  /* Auto layout */
  width: 280px;
  height: 58px;
  background: linear-gradient(270deg, #FFD500 0%, #FF8A05 99.99%, #FFAA00 100%);
  border-radius: 16px;

  font-family: 'Red Hat Display' !important;
  font-style: normal !important;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #172F4D;

}

.divider {
  height: 1px;
  background: #FFFFFF;
  opacity: 0.2;
  border: 1px solid #FFFFFF;
}

.pre-cadastro {
  width: 100%;
  min-height: 504;
  background-color: #F2F7FD;
  color: #1c4670 !important;
  padding-top: 25px;
  padding-bottom: 36px;
}

.pre-cadastro .pre-cadastro-logo-raio {
  width: 24px !important;
  height: 28px !important;
  margin-bottom: 16px !important;
}

.pre-cadastro p {
  font-family: 'Red Hat Display';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  color: #172F4D;
}

/************ Resultados Css ************/

.resultados {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 88px 0px;
  gap: 72px;
  background: #FF8500;
}

.resultados-header {
  font-family: 'Saira';
  font-style: normal;
  font-weight: 800;
  font-size: 40px;
  line-height: 120%;
  text-align: center;
  text-transform: uppercase;
  color: #1C4670;
}

.resultados-text {
  font-family: 'Saira';
  font-style: normal;
  font-weight: 800;
  font-size: 88px;
  line-height: 130%;
  color: #F2F7FD;
}

.resultados-subtext {
  font-family: 'Red Hat Display';
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 130%;
  text-align: center;
  text-transform: uppercase;
  color: #F2F7FD;
}

.resultados-info {
  font-family: 'Red Hat Display';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  color: #F2F7FD;
}